import base from "./base";
import axios from "@/http";
import store from "../store";

interface CourseData {
  campus: string;
  course_code: string;
  course_id: string;
  course_name: string;
  course_type: string;
  create_time: string;
  description: string;
  descriptionImages: string;
  faculty: string;
  original_price: number;
  price: number;
  semester: string;
  teacher: string;
}

interface SignedCourseData {
  student: string;
  student_name: string;
  advisor: string;
  advisor_name: string;
  transaction_id: string;
  course_code: string;
  campus: string;
  faculty: string;
  sign_time: string;
  paid_time: string;
  paid: boolean;
  advisor_note: string;
  is_quit: boolean;
  quit_time: string;
  quit_reason: string;
  original_price: number;
  deal_price: number;
}

const course = {
  errorCodes: base.errorCodeHelper(new Map([[404, "课程不存在"]])),
  get(courseCode: string) {
    return axios
      .get<CourseData>(`${base.api}/course/${courseCode}`)
      .catch(base.errorHandler.bind(this));
  },
  courseList: {
    errorCodes: base.errorCodeHelper(new Map([[404, "课程列表API失败"]])),
    get(faculty: string, params: Record<string, string>) {
      const campus = store.getters.getCurrentUser.campus;
      return axios
        .get<Array<CourseData>>(
          `${base.api}/campus/${campus}/faculty/${faculty}/courses`,
          {
            params: params
          }
        )
        .catch(base.errorHandler.bind(this));
    },
    getByCurrentTeacher() {
      const teacher = store.getters.getCurrentUser;
      return axios
        .get<Array<CourseData>>(
          `${base.api}/teacher/${teacher.username}/courses`
        )
        .catch(base.errorHandler.bind(this));
    }
  },
  orderList: {
    errorCodes: base.errorCodeHelper(new Map([[404, "课程列表API失败"]])),
    getOrderByCurrentStudent() {
      const user = store.getters.getCurrentUser;
      return axios
        .get<Array<SignedCourseData>>(`api/student/${user.username}/courses`)
        .catch(base.errorHandler.bind(this));
    }
  }
};
export default course;
