import { AxiosError } from "axios";

const base = {
  api: "api",
  errorCallBack: (msg: string) => {
    //fallback error handler. Will be replaced n app.vue
    console.error(msg);
  },

  errorCodeHelper: function (errorCodes: Map<number, string>) {
    const baseErrorCodes: Map<number, string> = new Map([
      [404, "请求资源不存在"],
      [409, "资源冲突"],
      [403, "无权访问"],
      [500, "服务器内部错误，请联系管理员"],
      [400, "非法请求"]
    ]);
    errorCodes.forEach(function (msg, code) {
      baseErrorCodes.set(code, msg);
    });

    return baseErrorCodes;
  },
  errorHandler: function (error: AxiosError) {
    if (error.response) {
      const msg = (this as any).errorCodes.get(error.response.status);
      if (msg != null) {
        base.errorCallBack(msg);
      }
      return Promise.reject(error);
    }
  }
};

export default base;
