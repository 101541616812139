
import { Component, Vue, Provide } from "vue-property-decorator";
@Component
export default class App extends Vue {
  isRouterAlive = true;
  @Provide() reload = "reloadFunction";
  created() {
    this.api.base.errorCallBack = (msg: string) => {
      this.$Message.error(msg);
    };
  }
  reloadFunction() {
    this.isRouterAlive = false;
    this.$nextTick(() => {
      this.isRouterAlive = true;
    });
  }
}
