import Vue from "vue";
import Vuex from "vuex";
import UserData from "./modules/UserData";
import QuestionsList from "./modules/QuestionsList";
import QuestionAnswer from "./modules/QuestionAnswer";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    UserData,
    QuestionsList,
    QuestionAnswer
  }
});
