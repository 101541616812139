/*
Main api export
*/
import course from "@/api/course";
import user from "@/api/user";
import base from "./base";
export default {
  course,
  user,
  base
};
