var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"homepage"},[_c('Row',{staticClass:"carousel-class"},[_c('Carousel',{staticClass:"carousel",attrs:{"autoplay":"","autoplay-speed":"4000","arrow":"never","loop":""}},[_c('CarouselItem',[_c('div',[_c('a',{attrs:{"slot":"extra","href":"https://space.bilibili.com/508229996","target":"_blank"},slot:"extra"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../../assets/封面.png"),"alt":"shangxue logo"}})])])]),_c('CarouselItem',[_c('div',[_c('a',{attrs:{"slot":"extra","href":"https://mp.weixin.qq.com/s/8VzYAuk7SJMZ9LRQo0u6sg","target":"_blank"},slot:"extra"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../../assets/封面2.png"),"alt":"shangxue logo"}})])])]),_c('CarouselItem',[_c('div',[_c('a',{attrs:{"slot":"extra","href":"https://mp.weixin.qq.com/s/8VzYAuk7SJMZ9LRQo0u6sg","target":"_blank"},slot:"extra"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../../assets/封面3.png"),"alt":"shangxue logo"}})])])])],1)],1),_c('Row',{staticStyle:{"margin-top":"12px"}},[_c('div',{staticStyle:{"font-weight":"bold","font-size":"2.35rem","text-align":"center"}},[_vm._v(" Explore Moxue ")]),_c('div',{staticStyle:{"font-size":"1rem","margin":"0.6rem 0","text-align":"center"}},[_vm._v(" We try our best to provide the best service ")]),_c('Row',{staticStyle:{"margin":"1rem 0"},attrs:{"gutter":8}},[_c('i-col',{attrs:{"span":"8"}},[_c('Card',{staticStyle:{"height":"100%"}},[_c('img',{staticStyle:{"width":"100%","max-height":"300px"},style:({
              cursor: 'pointer'
            }),attrs:{"src":require("../../assets/BuyCourse.png"),"alt":"shangxue logo"},on:{"click":function($event){return _vm.buyCourse()}}}),_c('div',{staticClass:"card1"},[_vm._v("Buy Online Course")])])],1),_c('i-col',{attrs:{"span":"8"}},[_c('Card',[_c('img',{staticStyle:{"width":"100%","max-height":"300px"},style:({
              cursor: 'pointer'
            }),attrs:{"src":require("../../assets/ContactUs.png"),"alt":"shangxue logo"},on:{"click":function($event){return _vm.contactUs()}}}),_c('div',{staticClass:"card1"},[_vm._v("Contact us")])])],1),_c('i-col',{attrs:{"span":"8"}},[_c('Card',[_c('a',{attrs:{"href":"/aboutus"}},[_c('img',{staticStyle:{"width":"100%","max-height":"300px"},style:({
                cursor: 'pointer'
              }),attrs:{"src":require("../../assets/AboutUs.jpg"),"alt":"shangxue logo"},on:{"click":function($event){return _vm.aboutUs()}}}),_c('div',{staticClass:"card1"},[_vm._v("About Us")])])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }