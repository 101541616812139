import axios from "axios";
import store from "./store";
import router from "./router";

axios.defaults.timeout = 30000;
const URL =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:8080"
    : "https://www.aucourse.com";
axios.defaults.baseURL = URL;
axios.interceptors.request.use(
  config => {
    if (store.getters.token) {
      // config.headers['Authentication-Token'] = store.getters.token
      config.headers.Authorization = localStorage.getItem("session");
    }
    // console.log("config header:" + config.headers['Authentication-Token'])
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          if ("Invalid Authentication" === error.response.data.message) {
            store.commit("logout");
            router.replace({
              path: "/login"
            });
          } else {
            return Promise.reject(error);
          }
          break;
        case 403:
          if (
            "Login time out" === error.response.data.message ||
            "User no longer exist" === error.response.data.message
          ) {
            store.commit("logout");
            router.replace({
              path: "/login"
            });
          }
          return Promise.reject(error);
        case 404:
          if ("User not exist" === error.response.data.message) {
            store.commit("logout");
            router.replace({
              path: "/login"
            });
          }
          return Promise.reject(error);
        case 500:
          alert("SERVER ERROR!! Please contact admin.");
          return Promise.reject(error);
        default:
          // console.log("default")
          return Promise.reject(error);
      }
    } else {
      console.error("Preflight Request Error");
      return Promise.reject(error);
    }
  }
);

export default axios;
