// initial state
const state = {
  data: {}
};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  setData(state, newData) {
    //设置参数
    state.data[newData.id] = [newData.answer, newData.correct];
  },
  clearData() {
    state.data = {};
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
