import Vue from "vue";
import App from "./App.vue";
import ViewUI from "view-design";
import router from "./router";
import "view-design/dist/styles/iview.css";
import "@/assets/main.css";
import store from "./store";
import axios from "./http";
import mavonEditor from "mavon-editor";
import "mavon-editor/dist/css/index.css";
import api from "./api";
import VueGtag from "vue-gtag";
import config from "./config";

declare module "vue/types/vue" {
  interface Vue {
    axios: typeof axios;
    api: typeof api;
  }
}
Vue.config.productionTip = false;
Vue.use(ViewUI);
Vue.use(mavonEditor);
Vue.use(
  VueGtag,
  {
    config: {
      id: config.gtagID
    }
  },
  router
);
Vue.prototype.axios = axios;
Vue.prototype.api = api;
new Vue({
  render: h => h(App),
  router,
  template: "<App/>",
  store,
  components: { App }
}).$mount("#app");
