<template>
  <div class="homepage">
    <Row class="carousel-class">
      <Carousel
        autoplay
        autoplay-speed="4000"
        arrow="never"
        loop
        class="carousel"
      >
        <CarouselItem>
          <div>
            <a
              href="https://space.bilibili.com/508229996"
              target="_blank"
              slot="extra"
            >
              <img
                src="../../assets/封面.png"
                alt="shangxue logo"
                style="width: 100%"
              />
            </a>
          </div>
        </CarouselItem>
        <CarouselItem>
          <div>
            <a
              href="https://mp.weixin.qq.com/s/8VzYAuk7SJMZ9LRQo0u6sg"
              target="_blank"
              slot="extra"
            >
              <img
                src="../../assets/封面2.png"
                alt="shangxue logo"
                style="width: 100%"
              />
            </a>
          </div>
        </CarouselItem>
        <CarouselItem>
          <div>
            <a
              href="https://mp.weixin.qq.com/s/8VzYAuk7SJMZ9LRQo0u6sg"
              target="_blank"
              slot="extra"
            >
              <img
                src="../../assets/封面3.png"
                alt="shangxue logo"
                style="width: 100%"
              />
            </a>
          </div>
        </CarouselItem>
      </Carousel>
    </Row>
    <Row style="margin-top: 12px">
      <div style="font-weight: bold; font-size: 2.35rem; text-align: center">
        Explore Moxue
      </div>
      <div style="font-size: 1rem; margin: 0.6rem 0; text-align: center">
        We try our best to provide the best service
      </div>
      <Row :gutter="8" style="margin: 1rem 0">
        <i-col span="8">
          <Card style="height: 100%">
            <img
              src="../../assets/BuyCourse.png"
              alt="shangxue logo"
              style="width: 100%; max-height: 300px"
              @click="buyCourse()"
              :style="{
                cursor: 'pointer'
              }"
            />
            <div class="card1">Buy Online Course</div>
          </Card>
        </i-col>
        <i-col span="8">
          <Card>
            <img
              src="../../assets/ContactUs.png"
              alt="shangxue logo"
              style="width: 100%; max-height: 300px"
              @click="contactUs()"
              :style="{
                cursor: 'pointer'
              }"
            />
            <div class="card1">Contact us</div>
          </Card>
        </i-col>
        <i-col span="8">
          <Card>
            <a href="/aboutus">
              <img
                src="../../assets/AboutUs.jpg"
                alt="shangxue logo"
                style="width: 100%; max-height: 300px"
                @click="aboutUs()"
                :style="{
                  cursor: 'pointer'
                }"
              />
              <div class="card1">About Us</div>
            </a>
          </Card>
        </i-col>
      </Row>
    </Row>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  methods: {
    buyCourse() {
      this.$router.push("/buycourse");
    },
    // contactUs() {
    //   this.$router.push("/contactus");
    // },
    aboutUs() {
      this.$router.push("/aboutus");
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.homepage {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}

.carousel-class {
  /* height: 40%; */
  width: 100%;
  display: flex;
  justify-content: center;
}

.carousel {
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.carousel-img {
  width: 100%;
  text-align: center;
  /* align-self: center; */
  position: relative;
  /* justify-self: center; */
}

.card1 {
  font-weight: bold;
  font-size: 1rem;
  margin: 0.6rem 0;
  text-align: center;
}

.word1 {
  font-size: 40px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.word-menu {
  font-size: 19px;
  font-weight: bold;
}
.button1 {
  font-size: 15px;
  color: black;
  position: absolute;
  top: 65%;
  left: 15.5%;
}
</style>
