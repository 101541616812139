const state = {
  questions: {
    //questionId:questionInfo
  }
};
const getters = {
  //获取vuex中储存的questionId
  idList(state) {
    const list = [];
    for (const key in state.questions) {
      list.push(key);
    }
    return list;
  },
  //获取与id相同的questionInfo
  questionInfo: state => id => {
    return state.questions[id];
  }
};

const mutations = {
  //添加题目列表
  addQuestions(state, payload) {
    state.questions[payload.id] = payload;
  },
  //删除题目
  delQuestion(state, questionId) {
    delete state.questions[questionId];
  },
  //修改题目
  changeQuestion(state, payload) {
    state.questions[payload.id] = payload;
  }
};
export default {
  state,
  getters,
  mutations
};
