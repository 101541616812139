import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import axios from "../http";
import store from "../store";
// import Home from '../views/Home.vue'
import Home from "../views/home/Home.vue";

Vue.use(VueRouter);

// const routes: Array<RouteConfig> = [
const routes = [
  {
    path: "/",
    redirect: "/dashboard"
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "Login - 登录"
    },
    component: () => import("@/views/Login.vue")
  },
  {
    path: "/register",
    name: "register",
    meta: {
      title: "Register - 注册"
    },
    component: () => import("@/views/Registe.vue")
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      title: "Dashboard - 学生管理页面",
      requireAuth: true
    },
    // 暂时改成 redirect 到资料下载页面
    // todo: 以后改成  /home
    redirect: "/usercentre/enrolledunits",
    // redirect: "/materials",
    component: () => import("@/views/dashboard/Dashboard.vue"),
    children: [
      {
        path: "/home",
        name: "home",
        meta: {
          title: "Home - 首页",
          requireAuth: true
        },
        // component: () => import("@/views/home/Home.vue"),
        component: Home
      },
      {
        path: "/aboutus",
        name: "aboutUs",
        meta: {
          title: "aboutUs - 关于我们",
          requireAuth: true
        },
        component: () => import("@/views/aboutUs/AboutUs.vue")
      },
      {
        path: "/contactus",
        name: "contactUs",
        meta: {
          title: "contactUs - 联系我们",
          requireAuth: true
        },
        component: () => import("@/views/contactUs/ContactUs.vue")
      },
      {
        path: "/enrolledunits",
        name: "enrolledUnits",
        meta: {
          title: "enrolledUnits - 我的选课",
          requireAuth: true
        },
        component: () => import("@/views/enrolledUnits/EnrolledUnits.vue")
      },
      {
        path: "/unitinfo",
        name: "unitInfo",
        meta: {
          title: "unitInfo - 课程详情",
          requireAuth: true
        },
        component: () => import("@/views/unitInfo/UnitInfo.vue")
      },
      {
        path: "/usercentre",
        name: "usercentre",
        meta: {
          title: "userCentre - 个人中心",
          requireAuth: true
        },
        component: () => import("@/views/userCentre/UserCentre.vue"),
        children: [
          { path: "login", redirect: "/login" },
          {
            path: "/usercentre/enrolledunits",
            alias: "/usercentre",
            name: "enrolledUnits",
            meta: {
              title: "enrolledUnits - 我的选课",
              requireAuth: true
            },
            component: () => import("@/views/enrolledUnits/EnrolledUnits.vue")
          },
          {
            path: "/usercentre/materials",
            name: "materials",
            meta: {
              title: "materials",
              requireAuth: true
            },
            component: () => import("@/views/materials/Materials.vue")
          }
        ]
      },
      {
        path: "/question",
        name: "question",
        meta: {
          title: "question - 刷题练习",
          requireAuth: true
        },
        component: () => import("@/views/questions/Question.vue")
      },

      {
        path: "/searchunit",
        name: "searchunit",
        meta: {
          title: "searchUnit - 搜索课程",
          requireAuth: true
        },
        component: () => import("@/views/searchUnit/SearchUnit.vue")
      },
      {
        path: "/myorder",
        name: "myorder",
        meta: {
          title: "myorder",
          requireAuth: true
        },
        component: () => import("@/views/myorder/MyOrder.vue")
      },
      {
        path: "/manageorder",
        name: "manageorder",
        meta: {
          title: "manageorder",
          requireAuth: true
        },
        component: () => import("@/views/manageOrder/ManageOrder.vue")
      },
      {
        path: "/materials",
        name: "materials",
        meta: {
          title: "materials",
          requireAuth: true
        },
        component: () => import("@/views/materials/Materials.vue")
      },
      {
        path: "/videos",
        name: "videos",
        meta: {
          title: "videos",
          requireAuth: true
        },
        component: () => import("@/views/videos/Videos.vue")
      },
      {
        path: "/manage-units",
        name: "manage-units",
        meta: {
          title: "All Units",
          requireAuth: true
        },
        component: () => import("@/views/manageUnits/manageUnits.vue")
      },
      {
        path: "/addnewunit",
        name: "addnewunit",
        meta: {
          title: "Add Unit",
          requireAuth: true
        },
        component: () => import("@/views/addNewUnit/AddNewUnit.vue")
      },
      {
        path: "/editunit",
        name: "EditUnit",
        meta: {
          title: "Edit Unit",
          requireAuth: true
        },
        component: () => import("@/views/editUnit/EditUnit.vue")
      },
      {
        path: "/adduser",
        name: "addUser",
        meta: {
          title: "Add User",
          requireAuth: true
        },
        component: () => import("@/views/addUser/addUser.vue")
      },
      {
        path: "/manageuser",
        name: "manageUser",
        meta: {
          title: "Del User",
          requireAuth: true
        },
        component: () => import("@/views/manageUser/ManageUser.vue")
      },
      {
        path: "/manage-campus",
        name: "manage-campus",
        meta: {
          title: "manage campus",
          requireAuth: true
        },
        component: () => import("@/views/manageCampus/ManageCampus.vue")
      },
      {
        path: "/edituser",
        name: "editUser",
        meta: {
          title: "Edit User",
          requireAuth: true
        },
        component: () => import("@/views/editUser/editUser.vue")
      }
    ]
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = new VueRouter({
  routes,
  mode: "history"
});

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    if (store.getters.token) {
      // console.log("router")
      // console.log(store.getters.token + "   router")
      axios.get("api/sessions").then(resp => {
        if (resp == null) {
          // This condition only helps Safari clean expired or invliad token.
          store.commit("logout");
          next({ name: "login" });
        }
        const userInfo = resp.data;
        // console.log(userInfo)
        store.commit("updateInfo", userInfo);
        next();
      });
      //next();
    } else {
      next({ name: "login" });
    }
  } else {
    // 路由部分

    next();
  }
});

export default router;
