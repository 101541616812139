import base from "./base";
import axios from "@/http";
export interface UserForm {
  username: string;
  password: string;
}
export interface UserData {
  campus: string;
  faculty: string;
  display_name: string;
  email: string;
  privilege: string;
  telephone: string;
  uid: string;
  user_type: string;
  username: string;
  wx: string;
}
export interface LoginResponse {
  user: UserData;
  token: string;
}
const user = {
  sessions: {
    errorCodes: base.errorCodeHelper(
      new Map([
        [404, "用户名不存在"],
        [400, "请求异常"],
        [401, "密码错误"]
      ])
    ),
    login(userForm: UserForm) {
      return axios
        .post<LoginResponse>("api/sessions", userForm)
        .catch(base.errorHandler.bind(this));
    }
  }
};
export default user;
