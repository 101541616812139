const state = {
  token: null,
  user: {
    username: "",
    uid: "",
    display_name: "",
    user_type: "",
    privilege: 0,
    telephone: null,
    campus: null,
    faculty: null,
    wx: null
  },
  course_list: []
};
const getters = {
  token(state) {
    if (!state.token) {
      state.token = localStorage.getItem("session");
    }
    return state.token;
  },
  getCurrentUser: state => state.user
};
const mutations = {
  login(state, { token, user }) {
    state.token = token;
    state.user = user;
    localStorage.setItem("session", token);
    sessionStorage.setItem("user_campus", user.campus);
    sessionStorage.setItem("username", user.username);
    sessionStorage.setItem("display_name", user.display_name);
  },
  updateInfo(state, user) {
    state.user = user;
    sessionStorage.setItem("user_campus", user.campus);
    sessionStorage.setItem("username", user.username);
    sessionStorage.setItem("display_name", user.display_name);
  },
  logout() {
    state.token = null;
    state.user = {
      username: "",
      uid: "",
      display_name: "",
      user_type: "",
      privilege: 0,
      telephone: null,
      campus: null,
      faculty: null,
      wx: null
    };
    localStorage.removeItem("session");
    sessionStorage.removeItem("user_campus");
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("display_name");
  }
};
export default {
  state,
  getters,
  mutations
};
